import React from 'react';
import styles from './list.module.css';
import { TextField, Button,Tooltip,Breadcrumbs,Typography} from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X, ArrowRight, Printer } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import AssignPopup from "./AssignPopup";
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import { ComponentToPrintList } from './ComponentToPrintList';
import { useReactToPrint } from 'react-to-print';
import { DateRangePicker } from 'rsuite';

function DataTableWithExport ({columns,data,customStyles}){
  const componentRef = React.useRef();
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{width:'auto'}}>Export To CSV</Button>;

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    
    const keys = ['post_code', 'booking_id', 'driver_name', 'pickup_address', 'contact_no', 'pickup_date', 'assigned_date'];

    result = '';
    keys.forEach(key => {
      if(key === 'post_code'){
        result += 'Post Code';
      }else if(key === 'booking_id'){
        result += 'Booking Id';
      }else if(key === 'driver_name'){
        result += 'Driver Name';
      }else if(key === 'pickup_address'){
        result += 'Pickup Address';
      }else if(key === 'contact_no'){
        result += 'Contact No';
      }else if(key === 'pickup_date'){
        result += 'Pickup Date';
      }else if(key === 'assigned_date'){
        result += 'Assigned Date';
      }else{
        result += key;
      }
      result += columnDelimiter;
    });
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  const downloadCSV = React.useCallback(() => {
    let newArray = data.map(row=>{
      return {'post_code': row?.post_code, 'booking_id': row?.reference_id, 'driver_name': row?.driver?.name, 'pickup_address': row?.pickup_details?.address.replace(/,/g, ""), 'contact_no': row?.pickup_details?.phone, 'pickup_date': moment(row.pickup_date).format('DD/MM/YYYY'), 'assigned_date': moment(row?.driver?.assigned_date).format('DD/MM/YYYY')}
    });

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;
    
    const filename = 'driver-jobs.csv';
    
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  },[data]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const actionsMemo = React.useMemo(() => <>
    <Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{width:'auto'}}>Print Bookings</Button>
  </>, [downloadCSV,handlePrint]);

  return (<>
    <div style={{ display: "none" }}>
      <ComponentToPrintList ref={componentRef} data={data} />
    </div>
    <DataTable className='DataTable' columns={columns} data={data} pagination customStyles={customStyles} actions={actionsMemo} />
  </>)
}

class BookingList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'', assignModalShow:false, selectedRows: [], bookingData: null, datefilterData: {start_date: '', end_date: '' }  };
    this.authData = authData();
    this.componentRef = [];

    this.columns = [{
        name: 'Post Code',
        selector: row => row?.post_code,
        sortable: true,
      },{
        name: 'Booking Id',
        selector: row =>  row?.reference_id,
        sortable: true,
      },{
        name: 'Driver Name',
        selector: row => row?.driver?.name,
        sortable: true,
      },{
        name: 'Pickup Address',
        selector: row => row?.pickup_details?.address,
        sortable: true,
      },{
        name: 'Contact No',
        selector: row => row?.pickup_details?.phone,
        sortable: true,
      },{
        name: 'Pickup Date',
        selector: row => moment(row.pickup_date).format('DD/MM/YYYY'),
        sortable: true,
      },{
        name: 'Assigned Date',
        selector: row => moment(row?.driver?.assigned_date).format('DD/MM/YYYY'),
        sortable: true
      },{
        name: 'Action',
        cell: (tableProps, index)  => (
          <div className={`${styles.ActionDiv}`}>
          <div style={{ display: "none" }}><ComponentToPrint ref={el => (this.componentRef[index] = el)} bookingData={tableProps} /></div>
          <ReactToPrint content={() => this.componentRef[index]}>
            <PrintContextConsumer>
              {({ handlePrint }) => ( <Button onClick={handlePrint}><Printer /></Button> )}
            </PrintContextConsumer>
          </ReactToPrint>
          <Tooltip title="Re-assign or un-assign" onClick={this.assignModalOpen.bind(this,tableProps?.id)}><Button><ArrowRight /></Button></Tooltip>
        </div> ),
        sortable: false
      }
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
    } 
  }

  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/assigned-bookings', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});

          allData.forEach((element,index) => {
            this.componentRef[index] = React.createRef();
          });
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });

    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }

  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item?.post_code.toLowerCase().includes(inputVal) || item?.reference_id.toLowerCase().includes(inputVal) || item?.driver?.name.toLowerCase().includes(inputVal) || item?.pickup_details?.address.toLowerCase().includes(inputVal) || item?.pickup_details?.phone.toLowerCase().includes(inputVal);
      });
    }

    let datefilterData = this.state.datefilterData;

    if(datefilterData?.start_date !== '' && datefilterData?.end_date !== ''){
      filteredData = filteredData.filter((item) => moment(item.pickup_date).isBetween(moment(datefilterData?.start_date),moment(datefilterData?.end_date), 'days', '[]'));
    }

    this.setState({ ...this.state, data: filteredData });
  }

  assignModalOpen(id){
    this.setState({...this.state, assignModalShow:true, selectedRows: [id]});
  }

  modalClose(){
    this.setState({...this.state, addModalShow:false,assignModalShow:false});
  }

  handleChange({ selectedRows }){
    let selectedRowTemp = selectedRows.map(item=>{
      return item.id;
    });
    this.setState({...this.state, selectedRows:selectedRowTemp});
  }

  assignDriver(message){
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    axios(process.env.REACT_APP_API_URL + 'v1/assigned-bookings', {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let allData = response.data.data;
        this.setState({ ...this.state, allData: allData, data: allData});
      }
    })
  }
  changeDate(e){
    let datefilterData = this.state.datefilterData;
    if(e){
      datefilterData = {start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
    }else{
      datefilterData = {start_date: '', end_date: '' };
    }
    this.setState({ datefilterData: datefilterData},this.filterHandler);
  }
  render() {
    return (<>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>View Driver Jobs</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Bookings</Typography>
            <Typography color="text.primary">Driver Jobs</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} autoComplete='off' />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
            <div style={{width:'320px', marginLeft:'16px'}}>
              <DateRangePicker
                showOneCalendar
                format="dd/MM/yyyy"
                placeholder="Search by date"
                ranges={[]}
                onChange={this.changeDate.bind(this)}
              /> 
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTableWithExport columns={this.columns} data={this.state.data} customStyles={this.customDataTableStyles} />
          </div>
        </div>
      </div>}

      <Modal show={this.state.assignModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Assign Booking Details To Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignPopup onSubmit={this.assignDriver.bind(this)} modalClose={this.modalClose.bind(this)} selectedRows={this.state.selectedRows} />
        </Modal.Body>
      </Modal>

      </>
    );
  }
}

export default withAdminAuth(BookingList);
import React from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import { useForm } from "react-hook-form";

export default function PaymentPopup ({modalClose,bookingId,paymentItem}){
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const onSubmitLocal = (data)=>{
    modalClose();
    setLoading(true);
    data={...data, payment_status:1};

    axios.put(process.env.REACT_APP_API_URL + 'v1/bookings/'+bookingId,data, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        paymentItem(response.data.message,bookingId);
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader /> }
    <div className={`${styles.FullDiv}`}>
      <h2 className={`${styles.PopupTitle}`}>Do you want to make payment now?</h2>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormGroupFull}`}>
            <label className={`${styles.FormLabel}`}>Resaon*</label>
            <textarea className={`${styles.FormControlTextArea}`} {...register("note",{required:'Please enter note'})}></textarea>
            {errors.note && <span className={`${styles.ErrorM}`}>{errors.note.message}</span>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="Cancel" type="button" onClick={modalClose.bind(this)} />
          <input className={`${styles.SubmitBU}`} value="Payment" type="submit" />
        </div>
      </form>
    </div>
  </React.Fragment>)
}
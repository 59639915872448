import React from 'react';
import styles from './AssignToDriver.module.css';
import { TextField, Button, Breadcrumbs, Typography  } from '@mui/material';
import DataTable from 'react-data-table-component';
import {  X, Plus, UserPlus, Edit } from 'react-feather';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Link } from '@mui/material';
import DriverAddForm from "../driver/addForm";
import AssignPopup from "./AssignToDriverPopup";
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ComponentToPrintList } from './AssignToDriverPrint';
import { useReactToPrint } from 'react-to-print';
import { DateRangePicker } from 'rsuite';
import NotePopup from './NotePopup';
import PaymentPopup from '../bookings/PaymentPopup';

function DataTableWithExport ({columns,data,customStyles,onSelectedRowsChange,rowDisabledCriteria}){
  const componentRef = React.useRef();
  
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{width:'auto'}}>Export To CSV</Button>;
  
  function convertArrayOfObjectsToCSV(array){
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = ['booking_id', 'customer_name', 'recepient_name', 'delivery_address', 'order_status', 'contact_no', 'container'];
    
    result = '';
    keys.forEach(key => {
      if(key === 'booking_id'){
        result += 'Booking Id';
      }else if(key === 'customer_name'){
        result += 'Customer Name';
      }else if(key === 'recepient_name'){
        result += 'Recepient Name';
      }else if(key === 'delivery_address'){
        result += 'Delivery Address';
      }else if(key === 'order_status'){
        result += 'Order Status';
      }else if(key === 'contact_no'){
        result += 'Contact No';
      }else if(key === 'container'){
        result += 'Container';
      }else{
        result += key;
      }
      result += columnDelimiter;
    });
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  const downloadCSV = React.useCallback(() => {
    let newArray = data.map(row=>{
      return {'booking_id': row?.reference_id, 'customer_name': row?.customer_name, 'recepient_name': row?.dropoff_details?.name, 'delivery_address': row?.dropoff_details?.address.replace(/,/g, ""), 'order_status': ((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid':'Unpaid'), 'contact_no': row?.dropoff_details?.phone, 'container': row?.container_name}
    });

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;
    
    const filename = 'driver-list.csv';
    
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  },[data]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{width:'auto'}}>Print Bookings</Button></>, [downloadCSV,handlePrint]);
  
  return <>
    <div style={{ display: "none" }}><ComponentToPrintList ref={componentRef} data={data} /></div>
    <DataTable
      className='DataTable'
      columns={columns}
      data={data}
      pagination
      selectableRows
      selectableRowDisabled={rowDisabledCriteria.bind(this)}
      onSelectedRowsChange={onSelectedRowsChange.bind(this)}
      customStyles={customStyles}
      actions={actionsMemo}
    />
  </>
}

class BookingList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [],filterText:'', addModalShow: false, assignModalShow:false, selectedRows: [], datefilterData: {start_date: '', end_date: '' },noteModalShow:false, editNote:null, paymentModalShow:false  };
    this.authData = authData();

    this.columns = [
      {
        name: 'Booking Id',
        selector: row =>  row?.reference_id,
        sortable: true,
      },
      {
        name: 'Customer Name',
        selector: row => row?.customer_name,
        sortable: true,
      },
      {
        name: 'Recepient Name',
        selector: row => row?.dropoff_details?.name,
        sortable: true,
      },
      {
        name: 'Delivery Address',
        selector: row => row?.dropoff_details?.address,
        sortable: true,
      },
      {
        name: 'Delivery Notes',
        selector: row => ((row?.delivery_note && row?.delivery_note !== '') ? <><div style={{marginRight:'25px'}}>{row?.delivery_note}</div><div style={{position: 'absolute', right: '3px', top: '5px'}}><Button className={`${styles.StatusBU2}`} onClick={this.noteModalOpen.bind(this,row, false)}><Edit /></Button></div></>: <Button className={`${styles.StatusBU}`} onClick={this.noteModalOpen.bind(this,row, true)}>Add Note</Button> ),
        sortable: true,
      },
      {
        name: 'Order Status',
        selector: tableProps => (<>
          {((tableProps?.payment_status === 1 || tableProps?.payment_status === '1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`}>Paid</Button>:<Button className={`${styles.StatusBU}`} onClick={this.paymentModalOpen.bind(this,tableProps?.id)}>Unpaid</Button>)}
        </>),
        sortable: true,
      },
      {
        name: 'Contact No',
        selector: row => row?.dropoff_details?.phone,
        sortable: true,
      },
      {
        name: 'Container',
        selector: row => row?.container_name,
        sortable: true,
      }
    ];

    this.customDataTableStyles = {
      head: {
        style: {
          zIndex:0
        },
      },
      headCells: {
        style: {
            backgroundColor: '#1e1e2d',
            color: '#fff'
        },
      },
    } 
  }
  
  
  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'v1/delivery/assign-bookings', {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then(response => {
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          this.setState({ ...this.state, allData: allData, data: allData,contentLoading:false});
        }else{
          this.setState({ ...this.state, contentLoading:false});
        }
      }).catch(error=>{
        this.setState({ ...this.state, contentLoading:false});
      });

    } catch (error) {
      this.setState({ ...this.state, contentLoading:false});
    }
  }

  noteModalOpen(item, isAdd){
    if(isAdd){
      this.setState({...this.state,noteModalShow:true, currentItem:item?.id, editNote: null});
    }else{
      this.setState({...this.state,noteModalShow:true, currentItem:item?.id, editNote: item?.delivery_note});
    }
  }

  onFilter(e){
    this.setState({ ...this.state, filterText: e.target.value },this.filterHandler);
  }
  clearFilter(){
    this.setState({ ...this.state, filterText: '' },this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return item?.reference_id.toLowerCase().includes(inputVal)|| item?.customer_name.toLowerCase().includes(inputVal) || item?.dropoff_details?.name.toLowerCase().includes(inputVal) || item?.dropoff_details?.address.toLowerCase().includes(inputVal) || item?.dropoff_details?.phone.toLowerCase().includes(inputVal) || item?.container_name.toLowerCase().includes(inputVal);
      });
    }

    let datefilterData = this.state.datefilterData;
    if(datefilterData?.start_date !== '' && datefilterData?.end_date !== ''){
      filteredData = filteredData.filter((item) => moment(item.pickup_date).isBetween(moment(datefilterData?.start_date),moment(datefilterData?.end_date), 'days', '[]'));
    }

    this.setState({ ...this.state, data: filteredData });
  }

  modalOpen(){
    this.setState({...this.state,addModalShow:true});
  }

  assignModalOpen(){
    this.setState({...this.state, assignModalShow:true});
  }
  paymentModalOpen(id){
    this.setState({...this.state,paymentModalShow:true, currentItem:id});
  }

  modalClose(){
    this.setState({...this.state, addModalShow:false,noteModalShow:false, paymentModalShow:false ,assignModalShow:false});
  }

  onSubmitAddForm(){
   console.log(111);
  }
  
  handleChange({ selectedRows }){
    let selectedRowTemp = selectedRows.map(item=>{
      return item.id;
    });
    this.setState({...this.state, selectedRows:selectedRowTemp});
  }

  rowDisabledCriteria(e){
    return parseInt(e?.payment_status) !== 1;
  }

  assignDriver(message){
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    axios(process.env.REACT_APP_API_URL + 'v1/delivery/assign-bookings', {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        let allData = response.data.data;
        this.setState({ ...this.state, allData: allData, data: allData});
      }
    })
  }

  changeDate(e){
    let datefilterData = this.state.datefilterData;
    if(e){
      datefilterData = {start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
    }else{
      datefilterData = {start_date: '', end_date: '' };
    }
    this.setState({ datefilterData: datefilterData},this.filterHandler);
  }

  noteItemAdd(message,item){
    toast.success(message);
    
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === item?.id)
        return {...i,delivery_note:item?.delivery_note}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === item?.id)
        return {...i,delivery_note:item?.delivery_note}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp});
  }

  paymentItem(message,id){
    toast.success(message);
    
    let allData = this.state.allData;
    let data = this.state.data;
    let allDataTemp = allData.map(i=> {
      if(i.id === id)
        return {...i,payment_status:1}
      return i;
    });
    let dataTemp = data.map(i=> {
      if(i.id === id)
        return {...i,payment_status:1}
      return i;
    });
    this.setState({ ...this.state, allData: allDataTemp, data: dataTemp,loading:false});
  }

  render() {
    return (
      <>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Assign Booking For Delivery</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Ghana Delvery</Typography>
            <Typography color="text.primary">Assign Driver</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} autoComplete='off' />
              {this.state.filterText && <Button type="button"  className="CloseBU" onClick={this.clearFilter.bind(this)}><X/></Button>}
            </div>
            <div style={{width:'320px', marginLeft:'16px'}}>
              <DateRangePicker
                showOneCalendar
                format="dd/MM/yyyy"
                placeholder="Search by date"
                ranges={[]}
                onChange={this.changeDate.bind(this)}
              /> 
            </div>
            <div className='TableFilterBUSec'>
              <Button className="TableAddBU" disabled={this.state.selectedRows.length === 0} onClick={this.assignModalOpen.bind(this)}><UserPlus/> Assign</Button>
              <Link className="TableAddBU" onClick={this.modalOpen.bind(this)}><Plus/> Driver</Link>
            </div>
          </div>
          
          <div className='TableContent'>
            <DataTableWithExport columns={this.columns} data={this.state.data} rowDisabledCriteria={this.rowDisabledCriteria.bind(this)} onSelectedRowsChange={this.handleChange.bind(this)} customStyles={this.customDataTableStyles} />
          </div>
        </div>
      </div>}

      <Modal show={this.state.addModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DriverAddForm onSubmit={this.onSubmitAddForm.bind(this)} modalClose={this.modalClose.bind(this)} isAddMode={true} id={0} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.assignModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Assign Booking Details To Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignPopup onSubmit={this.assignDriver.bind(this)} modalClose={this.modalClose.bind(this)} selectedRows={this.state.selectedRows} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.noteModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.editNote ? 'Edit Note':'Add Note'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotePopup modalClose={this.modalClose.bind(this)} noteItem={this.noteItemAdd.bind(this)} bookingId={this.state.currentItem} editNote={this.state.editNote} />
        </Modal.Body>
      </Modal>

      <Modal show={this.state.paymentModalShow} onHide={this.modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentPopup modalClose={this.modalClose.bind(this)} paymentItem={this.paymentItem.bind(this)} bookingId={this.state.currentItem} />
        </Modal.Body>
      </Modal>

      </>
    );
  }
}

export default withAdminAuth(BookingList);
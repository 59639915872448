import React, { useState,useEffect } from 'react';
import styles from './mybooking.module.css';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { Calendar, MapPin, Search } from 'react-feather';
import axios from 'axios';
import moment from 'moment';
import {authData} from "../../../admin/components/getAuth";
import Loader from  '../../../admin/components/Loader';
import Modal from 'react-bootstrap/Modal';
import withAdminAuth from "../../../admin/components/withAdminAuth";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelPopup from "./CancelPopup";
import DetailsPopup from "./DetailsPopup";
import {  useNavigate} from "react-router-dom";
import SideBar from "../../components/sideBar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ActionMenu ({bookingId,cancelModalOpen,status,detailsModalOpen,bookingIdStr}){
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trackBooking = (bId) =>{
    navigate('/customer/track-booking/'+bId);
  }

  return (<>
    <Button className={`${styles.ActionBU}`} disableElevation endIcon={<KeyboardArrowDownIcon />} onClick={handleClick}>Actions</Button>
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={`${styles.ActionMenuSec}`}>
      <MenuItem onClick={(e)=>{handleClose(); detailsModalOpen(bookingId,bookingIdStr); }}>View Booking</MenuItem>
      {(status === 0 || status === '0') && <MenuItem onClick={(e)=>{handleClose(); cancelModalOpen(bookingId); }}>Cancel Booking</MenuItem>}
      <MenuItem onClick={(e)=>{handleClose(); trackBooking(bookingIdStr); }}>Track Your Order</MenuItem>
    </Menu>
  </>)
}

function BookingBlock({item,cancelModalOpen,detailsModalOpen}){

  return (

      <div className={`${styles.BookingCardUnderTop}`}>
        <div className={`${styles.MyBookConHead}`}>
          <p className={`${styles.BookingCarDeTitle}`}>{item?.reference_id} {(item?.status === -1 || item?.status === '-1') ? <span className={`${styles.Cancelled}`}>Cancelled</span>:((item?.payment_status === 1 || item?.payment_status === '1')?<span className={`${styles.Confirmed}`}>Paid</span>:<span className={`${styles.Pending}`}>Unpaid</span>)}</p>
          <ActionMenu cancelModalOpen={cancelModalOpen.bind(this)} detailsModalOpen={detailsModalOpen.bind(this)} bookingId={item?.id} bookingIdStr={item?.reference_id} status={item?.status} />
        </div>

        <div className={`${styles.MyBookConDeSec}`}>          
          <p className={`${styles.BookingCarDeSTitle}`}><span>Customer Name:</span> {item?.customer_name}</p>
          <p className={`${styles.BookingCarDeSTitle}`}><span>Order Date:</span> {moment(item?.created_at).format('DD/MM/YYYY')}</p>
          <p className={`${styles.BookingCarDeSTitle}`}><span>Total Value:</span> &pound;{parseFloat(item.total).toFixed(2)}</p>
        </div>

        <div className={`${styles.MyBookConDeSec}`}>
          <p className={`${styles.BookingCarDeSTitle} ${styles.BookingCarDeSTitle2}`}>Pickup Details:</p>
          <p className={`${styles.BookingCarDeSTitle3}`}><Calendar/> {moment(item?.created_at).format('DD/MM/YYYY LT')}</p>
          <p className={`${styles.BookingCarDeSTitle3}`}><MapPin/> {item?.pickup_details?.address}</p>
        </div>        
      </div>

    );
}

function MyBookings() {
  const auth = authData();
  const [loading, setLoading] = React.useState(true);
  const [bookingList, setBookingList] = useState({all:[],loading:false});
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [selectIdStr, setSelectIdStr] = useState('');

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/customer-booking-list', {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        setBookingList({all:response.data.data,loading:false});
      }
    }).catch(error=>{
      setLoading(false);
    })
  },[auth?.api_token]);

  const modalClose = () =>{
    setCancelModalShow(false);
    setDetailsModalShow(false);
    setSelectId(0);
  }

  const cancelModalOpen = (id) =>{
    setCancelModalShow(true);
    setSelectId(id);
  }

  const detailsModalOpen = (id,idStr) =>{
    setDetailsModalShow(true);
    setSelectId(id);
    setSelectIdStr(idStr);
  }

  const camcelItem=(message,id)=>{
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    
    let allData = bookingList?.all;
    let allDataTemp = allData.map(i=> {
      if(i.id === id)
        return {...i,status:-1}
      return i;
    });
    setBookingList({all:allDataTemp,loading:false});
  }

  return (<>
    {loading && <Loader />}
    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/booking-bg.jpg" alt="" className={`${styles.BackGroundGra}`} />
      <div className={`${styles.Container}`}>
        <div className={`${styles.CusRow}`}>
          <div className={`${styles.CustomerSideBar}`}>
            <SideBar />
          </div>
          <div className={`${styles.CustomerDeSec}`}>
            <Box sx={{ width: '100%' }} className="CustomerDash">
              <div className={`${styles.BookingCard}`}>
                <div className={`${styles.BookingCardUnder}`}>

                <div className={`${styles.BookListSearch}`}>
                  <input type='text' placeholder='Search booking list'/>
                  <button><Search/></button>
                </div>
                {bookingList?.all?.map((item,index)=>{
                  return <BookingBlock key={index} item={item} cancelModalOpen={cancelModalOpen.bind(this)} detailsModalOpen={detailsModalOpen.bind(this)} />
                })}

                </div>
              </div>
              

              {(bookingList.all.length === 0) && <div className={`${styles.NofileSec}`}>
                <div className={`${styles.NofileSecUnder}`}>
                  <img src="/images/no-connection.png" alt="" />
                  <p>No booking are available now</p>
                </div>
              </div>}
            </Box>
          </div>
        </div>        
      </div>
    </div>

    <Modal show={cancelModalShow} onHide={modalClose.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CancelPopup modalClose={modalClose.bind(this)} bookingId={selectId} camcelItem={camcelItem.bind(this)} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={detailsModalShow} onHide={modalClose.bind(this)} centered className='CusModal OrderInfo'>
        <Modal.Header closeButton>
          <Modal.Title>Order Information: #{selectIdStr}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <DetailsPopup bookingId={selectId} />

        </Modal.Body>
      </Modal>
    </>
  );
}

export default withAdminAuth(MyBookings);
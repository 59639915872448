import React from 'react';
import styles from './AssignedDriver.module.css';
import moment from 'moment';

export const ComponentToPrint = React.forwardRef((props, ref) => {

  return (<div ref={ref}>

    {props?.bookingData?.products?.map(item=>{
      return <div style={{pageBreakBefore: 'always',padding: '50px'}}>
      <div style={{textAlign:'center',borderTop:'#000 2px solid',borderLeft:'#000 2px solid',borderRight:'#000 2px solid', margin:0, fontSize:'20px', fontWeight : '600'}}>
      AgyaKoo Shipping
    </div>
    <div style={{textAlign:'center',borderTop:'#000 2px solid',borderLeft:'#000 2px solid',borderRight:'#000 2px solid', margin:0, fontSize:'20px', fontWeight : '600'}}>
      Delivery Notes
    </div>
    <div style={{textAlign:'center',borderTop:'#000 2px solid',borderLeft:'#000 2px solid',borderRight:'#000 2px solid', margin:0, fontSize:'16px', fontWeight : '500'}}>
      International House, 142 Cromwell Road London, SW7 4ef
    </div>
    <div style={{textAlign:'center',borderTop:'#000 2px solid',borderLeft:'#000 2px solid',borderRight:'#000 2px solid',borderBottom:'#000 2px solid', margin:0, fontSize:'16px', fontWeight : '500'}}>
      Tel: 07853 607006
    </div>

    <div className={`${styles.PrintClass1}`} style={{marginTop: '30px'}}>
      <h4>{props?.bookingData?.delivery_driver?.name}</h4>
      <h3>{moment(props?.bookingData?.delivery_driver?.updated_at).format('dddd, D MMM YYYY')}</h3>
    </div>

    <div className={`${styles.PrintClass2}`}>
      <div className={`${styles.PrintClass3}`}>
        SL No.<br />1
      </div>
      <div className={`${styles.PrintClass4}`}>
        <h4>Booking Ref.</h4>
        <p>{props?.bookingData?.reference_id}</p>
        <br />
        <h4>Total</h4>
        <p>£{parseFloat(props?.bookingData?.total).toFixed(2)}</p>
      </div>
      <div className={`${styles.PrintClass5}`}>
        <h4>{props?.bookingData?.pickup_details?.name}</h4>
        <p>{props?.bookingData?.pickup_details?.phone}</p>
        <p></p>
        <p></p>
        <p></p>
        <p>------------------------------</p>
        <h4>{props?.bookingData?.dropoff_details?.name}</h4>
        <p>{props?.bookingData?.dropoff_details?.phone}</p>
        <br />
        <h5>Delivery Details</h5>
        <p>{props?.bookingData?.dropoff_details?.address}</p>
        <br />
        <h5>Delivery Note</h5>
        <p>{props?.bookingData?.delivery_note}</p>
      </div>
      <div className={`${styles.PrintClass5}`}>
        <div style={{border:'#000 2px solid', marginLeft: '30px', padding: '10px', fontWeight: '600'}}>{item?.item_id} - {item?.product_title_new}</div>
      </div>
    </div>
      </div>
    })}
    
    

  </div>);
});
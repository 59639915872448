import * as React from 'react';
import styles from './add.module.css';
import withAdminAuth from "../../components/withAdminAuth";
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Select from "react-select";
import Loader from '../../components/Loader';
import { useNavigate, useParams } from 'react-router';
import {authData} from "../../components/getAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import ContainerAddForm from './addContainer';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Mail, MapPin, Phone, X } from 'react-feather';
import { Alert, Breadcrumbs, Button, Snackbar, TextField, Typography } from '@mui/material';

const brandColor = '#CCCCCC';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

function AddShipping({ history, match }) {
  const auth = authData();
  const navigate = useNavigate();
  const { shippingId } = useParams();
  const [containerList, setContainerList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openAddContain, setOpenAddContain] = React.useState(false);
  const [WHItems, setWHItems] = React.useState([]);
  const [WHFilterItems, setWHFilterItems] = React.useState([]);
  const [containerItems, setContainerItems] = React.useState([]);
  const [containerFilterItems, setContainerFilterItems] = React.useState([]);
  const [containerId, setContainerId] = React.useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [shippingData, setShippingData] = React.useState(null);

  const { register, handleSubmit,control, formState: { errors }, setValue } = useForm({
    mode: 'onChange'});

  React.useEffect(()=>{
    let isAdd = 1;
    if(shippingId){
      isAdd =0;
    }
    axios.get(process.env.REACT_APP_API_URL + 'v1/container-dd-list/'+isAdd).then(response => {
      if(response.data.status === 'SUCCESS'){
        setContainerList(response.data.data);
      }
    })
  },[shippingId]);

  React.useEffect(()=>{
    if(shippingId){
      axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/'+shippingId).then(response => {
        if(response.data.status === 'SUCCESS'){
          let shipData = response.data.data;
          setShippingData(shipData);
          setValue('shipping_from',shipData.shipping_from);
          setValue('shipping_to',shipData.shipping_to);
          setValue('shipping_date',moment(shipData.shipping_date).toDate());
          setValue('eta_of_container',moment(shipData.eta_of_container).toDate());
          setValue('consignee',shipData.consignee);
          setValue('warehouse_location',{value: shipData.warehouse_location,label: shipData.warehouse_location});
          setValue('container_no',{value: shipData.container_no,label: shipData.container_name});

          setContainerId(shipData.container_no);
        }
      })
    }
  },[shippingId,containerList,setValue]);

  React.useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
      if(response.data.status === 'SUCCESS'){
        setWHItems(response.data.data);
      }
    });
  },[]);

  React.useEffect(()=>{
    if(containerId){
      axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-con/'+containerId).then(response => {
        if(response.data.status === 'SUCCESS'){
          setContainerItems(response.data.data);
        }
      });
    }
  },[containerId]);

  const onSubmit = (data)=>{
    setLoading(true);

    let postData = {container_no: data.container_no?.value };
    if(data?.consignee !== ''){
      postData = {...postData, consignee: data?.consignee };
    }
    if(data?.eta_of_container !== ''){
      postData = {...postData, eta_of_container: moment(data?.eta_of_container).format('YYYY-MM-DD') };
    }
    if(data?.shipping_date !== ''){
      postData = {...postData, shipping_date: moment(data?.shipping_date).format('YYYY-MM-DD') };
    }
    if(data?.shipping_from !== ''){
      postData = {...postData, shipping_from: data?.shipping_from };
    }
    if(data?.shipping_to !== ''){
      postData = {...postData, shipping_to: data?.shipping_to };
    }
    if(data?.warehouse_location){
      postData = {...postData, warehouse_location: data?.warehouse_location?.value };
    }

    if(shippingId){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/shippings/'+shippingId, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          navigate('/admin/shippings');
        }
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/shippings', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        if(response.data.status === 'SUCCESS'){
          navigate('/admin/shippings');
        }
      }).catch(error => {
        setLoading(false);
      });
    }
  }

  const containerAdd = () =>{
    setOpenAddContain(true);
  }

  const modalClose = () =>{
    setOpenAddContain(false);
  }

  const onSubmitAddForm = (conData) =>{
    let containerListTemp = containerList;
    containerListTemp.push({value:conData.id, label: conData.container_no+' ('+conData.seal_ref_no+')'});
    setValue('container_no',{value:conData.id, label: conData.container_no+' ('+conData.seal_ref_no+')'})
  }

  const cngContainer = (e) =>{
    setContainerId(e?.value);
  }

  const columns1 = [
    {
      name: 'Booking Id',
      selector: row => row?.reference_id,
      sortable: false,
    },
    {
      name: 'Items',
      selector: row => row?.no_of_item,
      sortable: false,
    },
    {
      name: 'Loaded',
      selector: row => row?.no_of_item3,
      sortable: false,
    },
    {
      name: 'Remaining',
      selector: row => row?.no_of_item2,
      sortable: false,
    },
    {
      name: '',
      cell: tableProps => (<Button className={`${styles.StatusBU}`} onClick={addToContainer.bind(this,tableProps)}>Add All</Button>),
      sortable: false,
    }
  ];

  const columns2 = [
    {
      name: 'Booking Id',
      selector: row => row?.reference_id,
      sortable: false,
    },
    {
      name: 'Items',
      selector: row => row?.no_of_item,
      sortable: false,
    },
    {
      name: 'Loaded',
      selector: row => row?.no_of_item3,
      sortable: false,
    },
    {
      name: 'Remaining',
      selector: row => row?.no_of_item2,
      sortable: false,
    },
    {
      name: '',
      cell: tableProps => (<Button className={`${styles.StatusBU}`} onClick={removeToContainer.bind(this,tableProps)} disabled={(shippingData?.status === 1 || shippingData?.status === '1')}>Remove All</Button>),
      sortable: false,
    }
  ];

  const columns3 = [
    {
      name: 'Product Id',
      selector: row => row?.item_id,
      sortable: false,
    },{
      name: 'Item',
      selector: row => row?.product_title_new,
      sortable: false,
    },{
      name: 'Status',
      selector: row => row?.status_text,
      sortable: false,
    },
    {
      name: '',
      cell: tableProps => ((tableProps?.is_wh_received === 1 && tableProps?.is_container_loaded === 0) ? <Button className={`${styles.StatusBU}`} onClick={addToContainer2.bind(this,tableProps)}>Add</Button>:null),
      sortable: false,
    }
  ];

  const columns4 = [
    {
      name: 'Product Id',
      selector: row => row?.item_id,
      sortable: false,
    },{
      name: 'Item',
      selector: row => row?.product_title_new,
      sortable: false,
    },{
      name: 'Status',
      selector: row => row?.is_container_loaded ? 'Item Loaded In Container' : 'Item In Warehouse - UK',
      sortable: false,
    },
    {
      name: '',
      cell: tableProps => ((tableProps?.is_container_loaded === 1) ? <Button className={`${styles.StatusBU}`} onClick={removeToContainer2.bind(this,tableProps)}  disabled={(shippingData?.status === 1 || shippingData?.status === '1')}>Remove</Button>:null),
    }
  ];

  const customDataTableStyles = {
    headRow: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      },
    },
  }

  const customDataTableStyles2 = {
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      },
    },
  }

  const addToContainer = (bookingData) =>{
    if(containerId === 0){
      setSnackOpen(true);
      return false;
    }
    let bookingId = bookingData?.id;
    axios.get(process.env.REACT_APP_API_URL + 'v1/item-loaded/'+containerId+'/'+bookingId).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-con/'+containerId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
          if(response.data.status === 'SUCCESS'){
            setWHItems(response.data.data);
          }
        });
      }
    });
  }

  const addToContainer2 = (bookingData) =>{
    if(containerId === 0){
      setSnackOpen(true);
      return false;
    }
    let bookingPId = bookingData?.id;
    let bookingId = bookingData?.booking_id;
    
    axios.get(process.env.REACT_APP_API_URL + 'v1/item-loaded/'+containerId+'/'+bookingId+'/'+bookingPId).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-con/'+containerId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
          if(response.data.status === 'SUCCESS'){
            setWHItems(response.data.data);
          }
        });
      }
    });
  }

  const removeToContainer = (bookingData) =>{
    let bookingId = bookingData?.id;
    axios.get(process.env.REACT_APP_API_URL + 'v1/item-unloaded/'+containerId+'/'+bookingId).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-con/'+containerId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
          if(response.data.status === 'SUCCESS'){
            setWHItems(response.data.data);
          }
        });
      }
    });
  }

  const removeToContainer2 = (bookingData) =>{
    if(containerId === 0){
      setSnackOpen(true);
      return false;
    }
    let bookingPId = bookingData?.id;
    let bookingId = bookingData?.booking_id;
    
    axios.get(process.env.REACT_APP_API_URL + 'v1/item-unloaded/'+containerId+'/'+bookingId+'/'+bookingPId).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-con/'+containerId).then(response => {
          if(response.data.status === 'SUCCESS'){
            setContainerItems(response.data.data);
          }
        });
        axios.get(process.env.REACT_APP_API_URL + 'v1/shippings/booking-list-wh').then(response => {
          if(response.data.status === 'SUCCESS'){
            setWHItems(response.data.data);
          }
        });
      }
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const markedContainer = () =>{
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URL + 'v1/marked-container/'+shippingId, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      if(response.data.status === 'SUCCESS'){
        navigate('/admin/shippings');
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  const ExpandedComponent1 = ({ data }) => <div className={`${styles.ExpandedDiv}`}>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf1}`}>
      <p>Pickup Address</p>
      <p className={`${styles.PickName}`}>{data?.pickup_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.pickup_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.pickup_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.pickup_details?.phone}</p>
    </div>
    <div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf2}`}>
      <p>Delivery Address</p>
      <p className={`${styles.PickName}`}>{data?.dropoff_details?.name}</p>
      <p className={`${styles.DetailsList}`}><MapPin/>{data?.dropoff_details?.address}</p>
      <p className={`${styles.DetailsList}`}><Mail/>{data?.dropoff_details?.email}</p>
      <p className={`${styles.DetailsList}`}><Phone/>{data?.dropoff_details?.phone}</p>
    </div>

    <div style={{margin:'0 5px 20px'}}>
      <DataTable className='DataTable' columns={columns3} data={data?.products} customStyles={customDataTableStyles2} />
    </div>
    
  </div>;

const ExpandedComponent2 = ({ data }) => <div className={`${styles.ExpandedDiv}`}>
<div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf1}`}>
  <p>Pickup Address</p>
  <p className={`${styles.PickName}`}>{data?.pickup_details?.name}</p>
  <p className={`${styles.DetailsList}`}><MapPin/>{data?.pickup_details?.address}</p>
  <p className={`${styles.DetailsList}`}><Mail/>{data?.pickup_details?.email}</p>
  <p className={`${styles.DetailsList}`}><Phone/>{data?.pickup_details?.phone}</p>
</div>
<div className={`${styles.ExpandedDivHalf} ${styles.ExpandedDivHalf2}`}>
  <p>Delivery Address</p>
  <p className={`${styles.PickName}`}>{data?.dropoff_details?.name}</p>
  <p className={`${styles.DetailsList}`}><MapPin/>{data?.dropoff_details?.address}</p>
  <p className={`${styles.DetailsList}`}><Mail/>{data?.dropoff_details?.email}</p>
  <p className={`${styles.DetailsList}`}><Phone/>{data?.dropoff_details?.phone}</p>
</div>

<div style={{margin:'0 5px 20px'}}>
  <DataTable className='DataTable' columns={columns4} data={data?.products} customStyles={customDataTableStyles2} />
</div>

</div>;

const cancelShip = () =>{
  navigate('/admin/shippings');
}

const cancelShipping = () =>{
  axios.get(process.env.REACT_APP_API_URL + 'v1/cancel-shipping/'+shippingId, {
    headers: {
      Authorization: `Bearer ${auth?.api_token}`,
    }
  }).then(response => {
    setLoading(false);
    if(response.data.status === 'SUCCESS'){
      navigate('/admin/shippings');
    }
  }).catch(error => {
    setLoading(false);
  });
}

const [filterText1, setFilterText1] = React.useState('');
const [filterText2, setFilterText2] = React.useState('');

const filterChange1 = (e) =>{
  setFilterText1(e.target.value);
}

const filterChange2 = (e) =>{
  setFilterText2(e.target.value);
}

const clearFilter1 = () =>{
  setFilterText1('');
}

const clearFilter2 = () =>{
  setFilterText2('');
}

React.useEffect(()=>{
  let WHItemsTemp = WHItems;

  if(filterText1 !== ''){
    let inputVal = filterText1;
    inputVal = inputVal.toLowerCase();
    WHItemsTemp = WHItemsTemp.filter((item) => {
      return item.reference_id.toLowerCase().includes(inputVal);
    });
  }

  setWHFilterItems(WHItemsTemp);
  
},[filterText1,WHItems]);

React.useEffect(()=>{
  let containerItemsTemp = containerItems;

  if(filterText2 !== ''){
    let inputVal = filterText2;
    inputVal = inputVal.toLowerCase();
    containerItemsTemp = containerItemsTemp.filter((item) => {
      return item.reference_id.toLowerCase().includes(inputVal);
    });
  }

  setContainerFilterItems(containerItemsTemp);
  
},[filterText2,containerItems]);


  return (<>
    {loading && <Loader /> }
    <div className={`${styles.MainDiv}`}>
    <div className={`${styles.PageTitleWrap}`}>
          {shippingId ? <p className={`${styles.PageTitle}`}>View Shipping</p>: <p className={`${styles.PageTitle}`}>Add Shipping</p>}
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Shippings</Typography>
            {shippingId ? <Typography color="text.primary">View</Typography>: <Typography color="text.primary">Add</Typography>}
            
          </Breadcrumbs>
        </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.StepOneForm}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.StepOneFormRow}`}>
              <div className={`${styles.StepOneFormFull}`}>
                <div className={`${styles.FormFieldHalf}`} style={{width:'50%'}}>
                  <label>Container*</label>
                  <div className={`${styles.FieldWrap}`}>
                    <Controller
                      control={control}
                      options={containerList}
                      {...register('container_no',{required:'Please select container_no'})}
                      render={({ field }) => (
                        <Select {...field} options={containerList} onChange={(e) => {field.onChange(e); cngContainer(e)}} className={`${styles.CustomSelect}`} styles={customStyles} isDisabled={shippingId}  />
                      )}
                    />
                    <button type='button' className={`${styles.AddBU}`} onClick={containerAdd}>Add</button>
                  </div>
                  {errors.container_no && <p className={`${styles.ErrorM}`}>{errors.container_no.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping From</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('shipping_from')} />
                  {errors.shipping_from && <p className={`${styles.ErrorM}`}>{errors.shipping_from.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping To</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('shipping_to')} />
                  {errors.shipping_to && <p className={`${styles.ErrorM}`}>{errors.shipping_to.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Shipping Date</label>
                  <Controller
                    control={control}
                    {...register('shipping_date')}
                    className={`${styles.TextField}`}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        autoComplete='off'
                        minDate={moment().toDate()}
                        selected={field.value}
                        className={`${styles.TextField}`}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => field.onChange(date)}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                  {errors.shipping_date && <p className={`${styles.ErrorM}`}>{errors.shipping_date.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Eta Of Container</label>
                  <Controller
                    control={control}
                    {...register('eta_of_container')}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        autoComplete='off'
                        minDate={moment().toDate()}
                        selected={field.value}
                        className={`${styles.TextField}`}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => field.onChange(date)}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                  {errors.eta_of_container && <p className={`${styles.ErrorM}`}>{errors.eta_of_container.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Consignee</label>
                  <input type="text" placeholder="" className={`${styles.TextField}`} {...register('consignee')} />
                  {errors.consignee && <p className={`${styles.ErrorM}`}>{errors.consignee.message}</p>}
                </div>
                <div className={`${styles.FormFieldHalf}`}>
                  <label>Warehouse Location</label>
                  <Controller
                      control={control}
                      options={[{value:'Ghana',label:'Ghana'}]}
                      {...register('warehouse_location')}
                      render={({ field }) => (
                        <Select {...field} options={[{value:'Ghana',label:'Ghana'}]} className={`${styles.CustomSelect}`} styles={customStyles} />
                      )}
                    />
                  {errors.warehouse_location && <p className={`${styles.ErrorM}`}>{errors.warehouse_location.message}</p>}
                </div>
              </div>
              <div className={`${styles.StepBUSec}`}>
                <button className={`${styles.Next}`} type='submit'>Save</button>
                <button className={`${styles.Next3}`} type='button' onClick={cancelShip}>Cancel</button>
                <Button className={`${styles.Next2}`} type='button' disabled={!shippingData || (shippingData && (shippingData?.status === 1 || shippingData?.status === '1' || shippingData?.status === 2 || shippingData?.status === '2'))} onClick={markedContainer.bind(this)}>Marked Container As Shipped</Button>
                <Button className={`${styles.Next2}`} type='button' disabled={!shippingData || (shippingData && (shippingData?.status === 1 || shippingData?.status === '1' || shippingData?.status === 2 || shippingData?.status === '2'))} onClick={cancelShipping}>Cancel Shipping</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={`${styles.MainCard2}`}>
        <div className={`${styles.MainCard2Half}`} style={{marginRight:'1%'}}>
          <p>Item In UK Warehouse</p>
          <div className="DataTableSearch" style={{marginBottom: "5px"}}>
            <TextField id="search" type="text" placeholder="Search by booking id" aria-label="Search By Booking ID" value={filterText1} onChange={filterChange1} autoComplete='off'  />
            {(filterText1 !== '') && <Button type="button"  className="CloseBU" onClick={clearFilter1} ><X/></Button>}
          </div>
          <DataTable className='DataTable' columns={columns1} data={WHFilterItems} customStyles={customDataTableStyles} expandableRows expandableRowsComponent={ExpandedComponent1} />
        </div>
        <div className={`${styles.MainCard2Half}`} style={{marginLeft:'1%'}}>
          <p>Container Details</p>
          <div className="DataTableSearch" style={{marginBottom: "5px"}}>
            <TextField id="search" type="text" placeholder="Search by booking id" aria-label="Search By Booking ID" value={filterText2} onChange={filterChange2} autoComplete='off'  />
            {(filterText2 !== '') && <Button type="button"  className="CloseBU" onClick={clearFilter2} ><X/></Button>}
          </div>
          <DataTable className='DataTable' columns={columns2} data={containerFilterItems} customStyles={customDataTableStyles} expandableRows expandableRowsComponent={ExpandedComponent2} />
        </div>
      </div>
    </div>

      <Modal show={openAddContain} onHide={modalClose} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Add Container</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerAddForm onSubmit={onSubmitAddForm.bind(this)} isAddMode={true} id={0} modalClose={modalClose} />
        </Modal.Body>
      </Modal>

      <Snackbar
        open={snackOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Please select container first!!
        </Alert>
      </Snackbar>
  </>);
}

export default withAdminAuth(AddShipping);
import React from 'react';
import { Sliders, Users, Calendar, UserCheck, Settings, Truck, Send } from 'react-feather';
import { Link } from '@mui/material';
import {  Link as RouterLink  } from "react-router-dom";
import styles from './footer.module.css';
import { ToastContainer } from 'react-toastify';

export default function Footer({menuName}) {

  return (<>
    <footer className={`${styles.FooterMain}`}>
      <div className={`${styles.SideAvatarMain}`}>
        <RouterLink to={'/admin/dashboard'}>
          <img src="/images/admin-logo.svg" alt="" />
        </RouterLink>
      </div>
      <div className={`${styles.SideMenuLinkSec}`}>
        <div className={`${styles.SideMenuLinkSecUnder}`}>
          <ul>
            <li className={(menuName === 'dashboard')?`${styles.Active}`:``}>
              <RouterLink to={'/admin/dashboard'}>
                <Sliders/>
                <p className={`${styles.SideLinkText}`}>Dashboard</p>
              </RouterLink>
            </li>
            <li className={(menuName === 'booking')?`${styles.DropDownSec} ${styles.Active}`:`${styles.DropDownSec}`}>
              <Link>
                <Calendar/>
                <p className={`${styles.SideLinkText}`}>Bookings</p>
              </Link>
              <ul className={`${styles.DropDownList}`}>
                <li>
                  <RouterLink to={'/admin/add-booking'}>Make A Booking</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/bookings/assign-list'}>Assign Bookings To Driver</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/bookings'}>View All Bookings</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/bookings/driver-jobs'}>View Driver Jobs</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/bookings/picked-items'}>View Picked Items</RouterLink>
                </li>
              </ul>
            </li>
            <li className={(menuName === 'shipping')?`${styles.DropDownSec} ${styles.Active}`:`${styles.DropDownSec}`}>
              <Link>
                <Send />
                <p className={`${styles.SideLinkText}`}>Shippings</p>
              </Link>
              <ul className={`${styles.DropDownList}`}>
                <li>
                  <RouterLink to={'/admin/shipping/container-list'}>Containers</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/add-shipping'}>Add Shipping</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/shippings'}>View All Shippings</RouterLink>
                </li>
              </ul>
            </li>
            <li className={(menuName === 'delivery')?`${styles.DropDownSec} ${styles.Active}`:`${styles.DropDownSec}`}>
              <Link>
                <Truck />
                <p className={`${styles.SideLinkText}`}>Ghana Delivery</p>
              </Link>
              <ul className={`${styles.DropDownList}`}>
                <li>
                  <RouterLink to={'/admin/delivery/receive-shipping'}>Receive Shipping</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/delivery/assign-to-driver'}>Assign Booking For Delivery</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/delivery/driver-jobs'}>View Driver Job For Delivery</RouterLink>
                </li>
              </ul>
            </li>
            <li className={(menuName === 'customer')?`${styles.Active}`:``}>
              <RouterLink to={'/admin/customers'}>
                <Users/>
                <p className={`${styles.SideLinkText}`}>Customers</p>
              </RouterLink>
            </li>
            <li className={(menuName === 'driver')?`${styles.Active}`:``}>
              <RouterLink to={'/admin/drivers'}>
                <UserCheck/>
                <p className={`${styles.SideLinkText}`}>Drivers</p>
              </RouterLink>
            </li>
            <li className={(menuName === 'settings')?`${styles.DropDownSec} ${styles.Active}`:`${styles.DropDownSec}`}>
              <Link>
                <Settings/>
                <p className={`${styles.SideLinkText}`}>Settings</p>
              </Link>
              <ul className={`${styles.DropDownList}`}>
              <li>
                  <RouterLink to={'/admin/products'}>PIckup Item</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/delivery-products'}>Delivery Item</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/users'}>User Management</RouterLink>
                </li>
                <li>
                  <RouterLink to={'/admin/user-permissions'}>User Permissions</RouterLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </footer>
    <ToastContainer limit={1} />
  </>);
}